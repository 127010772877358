<template>
  <v-container :fluid="breakpoint === 'md'">
    <template v-if="!clientLoaded || !permissionsFetched">
      <v-layout align-center class="loader-layout">
        <v-flex>
          <pas-circular-loader :loadingMessage="`It'll Just Be A Moment...`" />
        </v-flex>
      </v-layout>
    </template>
    <template v-else-if="clientLoaded && permissionsFetched">
      <v-layout row>
        <v-flex xs3 md3 lg2>
          <profile-avatar
            v-if="canViewBasicClientInformation"
            :riskScore="Number(activeClient.riskScores.overall)"
            :avatarImage="activeClient.avatar"
            :readonly="!canEditBasicClientInformation"
          />
        </v-flex>
        <v-flex
          xs6
          md6
          lg7
          ml-3
          :pr-5="['md', 'lg', 'xl'].includes(breakpoint)"
          :pr-1="['xs', 'sm'].includes(breakpoint)"
        >
          <profile-bio
            v-if="canViewBasicClientInformation"
            :activeClient="activeClient"
            :employment="activeClient.employment"
            :workAddress="workAddress"
          />
        </v-flex>
        <v-flex xs3 md3 lg3>
          <v-layout column pb-4>
            <v-flex text-xs-right>
              <v-layout row justify-end>
                <v-flex shrink>
                  <pas-more-menu
                    :editOptions="editOptions"
                    @click="$event()"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex>
              <relationships
                :relationships="relationships"
                :clientType="clientType"
                v-if="canSeeRelationships"
              />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-spacer />
        <v-flex shrink>
          <v-layout row nowrap child-flex :style="{ width: 'fit-content !important' }">
            <div v-if="canViewDocuments" class="mr-2">
              <router-link :to="`/clients/${clientID}/documents`" class="action-text-lower underline-hover margin-auto">View or Add Documents</router-link>
            </div>
            <div v-if="clientRelationshipsLinkVisible" class="mr-2">
              <router-link :to="`/clients/${clientID}/relationships`" class="action-text-lower underline-hover margin-auto">
                {{ clientType === 'person' ? 'View Business and Direct Relationships' : 'View Legal Entity Roles' }}
              </router-link>
            </div>
            <div v-if="isPasAdmin" class="underline-hover">
              <a class="action-text margin-auto" @click="triggerEquifax">Run Equifax Report</a>
            </div>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout mt-3>
        <financial-overview v-if="canViewFinancialProfile" />
      </v-layout>
      <v-layout
        mt-3
        row
        :wrap="['xs', 'sm', 'md'].includes(breakpoint)"
        :justify-center="['xs', 'sm', 'md'].includes(breakpoint)"
        :justify-space-between="['lg', 'xl'].includes(breakpoint)"
      >
        <v-flex xs12 md10 lg5 :mr-4="['lg', 'xl'].includes(breakpoint)">
          <workflowsV2
            v-if="hasFeatureFlag('new_workflows') && canViewWorkflowsDashboard"
            :workflows="activeClient.workflows"
            :workflows-count="activeClient.workflowsCount"
            :client-id="activeClient.client.id"
          />
          <workflows v-else :workflows="activeClient.workflows" />
        </v-flex>
        <v-flex xs12 md10 lg7 :mt-5="['xs', 'sm', 'md'].includes(breakpoint)">
          <accounts
            :investmentAccounts="investmentAccountsWithSystematics"
            :bankAccounts="activeClient.bankAccounts"
            :person="activeClient.person"
          />
        </v-flex>
      </v-layout>
    </template>
    <client-menu
      v-if="clientLoaded && permissionsFetched"
      :client="activeClient.client"
      :investment_accounts="activeClient.investment_accounts"
      :banking_accounts="activeClient.bankAccounts"
      :transfers="activeClient.transfers"
    />
  </v-container>

</template>

<script>
import { mapGetters } from 'vuex';
import { RELATIONS } from 'Services/constants';
import ProfileAvatar from 'Components/Vue-Routes/MyClients/ClientView/ClientHome/ProfileAvatar';
import ProfileBio from 'Components/Vue-Routes/MyClients/ClientView/ClientHome/ProfileBio';
import Relationships from 'Components/Vue-Routes/MyClients/ClientView/ClientHome/Relationships';
import FinancialOverview from 'Components/Vue-Routes/MyClients/ClientView/ClientHome/FinancialOverview';
import Accounts from 'Components/Vue-Routes/MyClients/ClientView/ClientHome/Accounts';
import Workflows from 'Components/Vue-Routes/MyClients/ClientView/ClientHome/Workflows';
import WorkflowsV2 from 'Components/Vue-Routes/MyClients/ClientView/ClientHome/WorkflowsV2';
import ClientMenu from 'Components/Vue-Routes/MyClients/ClientView/ClientMenu'; // legacy menu
import api from 'Services/api';
import timeline from 'Assets/icons/timeline.svg';
import { editClientRoute } from 'Services/mixins';

export default {
  name: 'client-home',
  components: {
    ProfileAvatar,
    ProfileBio,
    Relationships,
    FinancialOverview,
    Workflows,
    Accounts,
    ClientMenu,
    WorkflowsV2,
  },
  mixins: [editClientRoute],
  props: {
    clientID: String,
    clientLoaded: Boolean,
  },
  data() {
    return {
      editOptions: [
        {
          title: 'View Full Profile',
          onClick: () => {
            this.$router.push(this.editClientRoute(this.activeClient.client));
          },
        },
      ],
      timeline,
    };
  },
  computed: {
    ...mapGetters([
      'permissionsFetched',
      'canViewBasicClientInformation',
      'canViewFinancialProfile',
      'canEditBasicClientInformation',
      'canViewClientRelationships',
      'canViewWorkflowsDashboard',
      'canViewDocuments',
      'hasFeatureFlag',
    ]),
    activeClient() {
      return this.$store.state.clients.activeClient;
    },
    primaryColor() {
      return this.$store.state.app.themeVars['primary-translucent-2'];
    },
    clientType() {
      return this.activeClient && this.activeClient.client ? this.activeClient.client.client_type : '';
    },
    workAddress() {
      return this.activeClient.addresses.find((a) => a.client_address_type === 'E');
    },
    isPasAdmin() {
      return this.$store.state.users.currentUser.role === 'SA'; // NOTE: do we need a pas admin also?
    },
    canSeeRelationships() {
      return this.canViewClientRelationships;
    },
    relationships() {
      return this.activeClient.relationships
        .map((r) => {
          let { relationship_type, relationship_id, inverse_relationship_id } = r;
          const display_name = r.client_details[0] ? r.client_details[0].display_name : '';
          const client_2_id = r.client_details[0] ? r.client_details[0].id : '';
          relationship_type = RELATIONS[relationship_type];

          return {
            client_2_id,
            relationship_id,
            relationship_type, // ex "Parent"
            display_name, // client2 display name
            inverse_relationship_id, // client2 id
          };
        });
    },
    clientRelationshipsLinkVisible() {
      return this.clientType === 'person' || this.canViewClientRelationships;
    },
    breakpoint() {
      return this.$vuetify.breakpoint.name;
    },
    investmentAccountsWithSystematics() {
      return this.$store.getters.investmentAccountsWithSystematics;
    },
    env() {
      return this.$store.state.app.env;
    },
  },
  methods: {
    snackbar(type) {
      const text = `No ${type} record on file for ${this.activeClient.client.display_name}`;
      this.$store.dispatch('setSnackbar', { text, type: 'fail' });
      this.$store.dispatch('flipSnackbarSwitch');
    },
    async triggerEquifax() {
      const { client } = this.activeClient;

      this.$store.dispatch('setSnackbar', {
        text: `Running Equifax report for ${client.display_name} [ClientID = ${client.id}], you should receive an email shortly.`,
        type: 'success',
      });
      this.$store.dispatch('flipSnackbarSwitch');

      await api.post(`/advisor_portal/check_client_with_equifax?client_id=${client.id}`, {});
    },
  },
};

</script>

<style>
  .pas-dot {
    color: var(--primary-color);
    margin-top: 42px
  }
  .loader-layout {
    margin-top: 200px;
  }
  .inline-edit-field .v-input__control .v-input__slot:before {
    border-color: rgba(0,0,0,0.25) !important;
  }
</style>
