/* eslint-disable max-len */
/* eslint-disable import/extensions */
/* eslint-disable no-restricted-syntax */
import api from 'Services/api';
import apiV2 from 'Services/apiV2';
import { sendMessage } from 'Services/api/Workflows';

const FATCARenewalStatuses = {
  completed: { start: 60, end: null },
  upcoming: { start: 14, end: 60 },
  at_risk: { start: 0, end: 14 },
  overdue: { start: null, end: 0 },
};

function getFATCARenewalStatusForNextRenewalDueDate(next_fatca_renewal_due) {
  const diffDays = Math.ceil((new Date(next_fatca_renewal_due) - new Date()) / (1000 * 60 * 60 * 24));

  for (const status in FATCARenewalStatuses) {
    const { start, end } = FATCARenewalStatuses[status];

    if ((start === null || diffDays >= start) && (end === null || diffDays <= end)) {
      return status;
    }
  }
}

function getFATCARenewalDateRangeForStatus(status) {
  if (!FATCARenewalStatuses[status]) {
    return null;
  }

  const { start, end } = FATCARenewalStatuses[status];
  const addDays = (days) => (days !== null ? new Date(new Date().getTime() + days * 1000 * 60 * 60 * 24) : null);

  return {
    startDate: addDays(start),
    endDate: addDays(end),
  };
}

export async function fetchFATCARenewalsList(params) {
  const queryParams = new URLSearchParams({
    limit: params.limit,
    name: params.searchVal,
    email: params.searchVal,
    search_val: params.searchVal,
    offset: params.offset,
    sort_by: `next_fatca_renewal_due`,
    sort_dir: params.sortDirection,
    team_ids: params.teamIds,
    client_id: params.clientId,
    type: 'person',
  });

  if (params.status) {
    const { startDate, endDate } = getFATCARenewalDateRangeForStatus(params.status);
    const formatDate = (date) => date ? date.toISOString().split('T')[0] : null;

    if (startDate) {
      queryParams.set('next_fatca_renewal_due_start_date', formatDate(startDate));
    }

    if (endDate) {
      queryParams.set('next_fatca_renewal_due_end_date', formatDate(endDate));
    }
  }

  const falsyValues = [];
  queryParams.forEach((value, key) => {
    if (['null', 'undefined', ''].includes(value)) {
      falsyValues.push(key);
    }
  });

  falsyValues.forEach((key) => {
    queryParams.delete(key);
  });

  const response = await api.get(`${api.axonUrl()}/clients/v1/clients/?${queryParams}`);

  response.result.forEach(item => {
    item.fatca_renewal_status = getFATCARenewalStatusForNextRenewalDueDate(item.next_fatca_renewal_due);
  });

  return response.result;
};

export const fetchFATCARenewaltatusCount = () => {
  const encodedFATCARenewalStatuses = encodeURIComponent(JSON.stringify(FATCARenewalStatuses));
  const url = `${api.datacaptureUrl()}/fatca_renewals_count_by_status/?fatca_renewal_statuses=${encodedFATCARenewalStatuses}`;

  return api.get(url);
};

export async function createAndStartFATCARenewalWorkflow(clientGuid) {
  const createResponse = await api.post(`${api.axonUrl()}/clients/v2/clients/workflow/create_fatca_renewal/${clientGuid}/`);
  const workflowId = createResponse.result.workflow_id;

  apiV2.post('/fatca_renewal_questions/', {
    client_id: clientGuid,
    workflow_id: workflowId,
  })
  .then((res) => res.results || [])
  .catch((err) => []);

  return await sendMessage(
    { id: workflowId },
    { messageType: `workflow.fatca_repapering.create.success` },
  );
};

export const fetchFATCADiff = ({ clientId, workflowId }) =>
  apiV2.get('/fatca_renewal_questions/', {
    client_id: clientId,
    workflow_id: workflowId,
  })
  .then((res) => res.results || [])
  .catch((err) => []);
