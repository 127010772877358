import Vue from 'vue';
import _ from 'lodash';
import api from 'Services/api';
import apiV2 from 'Services/apiV2';
import { deleteAccount } from 'Services/api/accounts';
import {
  ATON_TRANSFER, CLIENTSTATUS,
} from 'Services/constants';
import moment from 'moment';
import {
  fetchClientReportingGroup, fetchClientPMGroup,
} from 'Services/api/ClientGroupings';
import { fetchFeeGroup } from 'Services/api/FeeManagement';
import {
  fetchClientIPInviteStatus, fetchInvestorAccountStatus, fetchEmailChangeRequest, fetchVerificationStatus,
} from 'Services/api/InvestorPortal';
import { fetchClientTaxAns, fetchClientRiskProfile } from 'Services/api/Client';
import { fetchClientPortfolio } from 'Services/api/PortfolioAssignment';

import accounts from './clients/accounts';
import { fetchWorkflowsForClient } from '../../services/api/Workflows';

const axonUrl = localStorage.getItem('axonUrl');

/* Defining the defaultState allows access to it in the mutations/actions for resetting etc. */
const defaultState = {
  list: ['loading'],
  transfers: ['loading'],
  atonTransfers: ['loading'],
  activeClient: {
    client: null,
    person: {},
    corporation: {},
    foundation: {},
    trust: {},
    ipp: {},
    nonprofit: {},
    bankAccounts: [],
    relationships: [],
    corporate_roles: [],
    workflows: [],
    workflowsCount: 0,
    investment_accounts: [],
    systematics: [],
    account_values: [],
    total_aum: '',
    transfers: [],
    riskScores: {}, // datahub_risk_scores, will be deprecated
    riskProfile: {}, // datahub_client_risk_profile
    portfolio: {},
    addresses: [],
    financials: {},
    employment: {},
    fundsSources: {},
    documents: [],
    profileLoading: false,
    feeGroup: {},
    reportingGroup: {},
    pmGroup: {},
    taxAns: {},
    IPInviteStatus: null,
    IPAccountStatus: null,
    IPVerificationStatus: null,
    emailChangeRequestStatus: null,
    suitabilityScore: {},
  },
};

const state = _.cloneDeep(defaultState);

const mutations = {
  SET_CLIENTS(state, clients) {
    if (clients && clients.length > 0 && typeof clients[0] !== 'string') {
      // filter out KYC attribute profiles created by data-capture
      clients = clients.filter((c) => c.is_kyc_attribute_client !== true);
    }
    state.list = clients;
  },
  SET_CLIENT(state, client) {
    // strip out nested objects
    client = Object.keys(client).reduce((acc, k) => {
      if (!(typeof client[k] === 'object')) {
        acc[k] = client[k];
        return acc;
      } return acc;
    }, {});
    state.activeClient.client = client;
  },
  SET_PERSON(state, person) {
    state.activeClient.person = person;
  },
  SET_CORPORATION(state, corporation) {
    state.activeClient.person = corporation;
  },
  SET_FOUNDATION(state, foundation) {
    state.activeClient.person = foundation;
  },
  SET_TRUST(state, trust) {
    state.activeClient.person = trust;
  },
  SET_NON_PROFIT(state, nonprofit) {
    state.activeClient.person = nonprofit;
  },
  SET_IPP(state, ipp) {
    state.activeClient.person = ipp;
  },
  RESET_ACTIVE_CLIENT(state) {
    state.activeClient = _.cloneDeep(defaultState.activeClient);
  },
  SET_BANK_ACCOUNTS(state, bankAccounts) {
    state.activeClient.bankAccounts = bankAccounts || [];
  },
  UPDATE_BANK_ACCOUNTS(state, bankAccounts) {
    state.activeClient.bankAccounts = [...state.activeClient.bankAccounts, ...bankAccounts];
  },
  SET_TRANSFERS(state, transfers) {
    state.activeClient.transfers = transfers || [];
  },
  SET_RELATIONSHIPS(state, relationships) {
    if (relationships) {
      // filter out KYC attribute profiles created by data-capture
      relationships = relationships.filter((r) => (r.client_details[0] ? r.client_details[0].is_kyc_attribute_client !== true : true));
    }
    state.activeClient.relationships = relationships || [];
  },
  REMOVE_RELATIONSHIP(state, relationshipID) {
    state.activeClient.relationships = state.activeClient.relationships
      .filter((r) => r.relationship_id !== relationshipID);
  },
  SET_WORKFLOWS(state, workflows) {
    state.activeClient.workflows = workflows;
  },
  SET_TOTAL_WORKFLOWS(state, totalCount) {
    state.activeClient.workflowsCount = totalCount;
  },
  SET_RRIF_PAYMENTS(state, rrifPayments) {
    state.activeClient.rrifPayments = rrifPayments;
  },
  SET_INVESTMENT_ACCOUNTS(state, investment_accounts) {
    state.activeClient.investment_accounts = investment_accounts;
  },
  SET_ACCOUNT_VALUES(state, account_values) {
    state.activeClient.account_values = account_values;
  },
  ADD_ACCOUNT_VALUES_TO_ACCOUNTS(state) {
    state.activeClient.account_values.forEach((av) => {
      const matchingAccount = state.activeClient.investment_accounts.find((ia) => ia.id === av.id);
      if (matchingAccount) Vue.set(matchingAccount, 'value', av.value);
    });
  },
  SET_TOTAL_AUM(state, total_aum) {
    state.activeClient.total_aum = total_aum;
  },
  SET_SYSTEMATICS(state, systematics) {
    state.activeClient.systematics = systematics;
  },
  ADD_SYSTEMATIC(state, systematic) {
    const account_systematics = state.activeClient.systematics.find((item) => item.account_info.id === systematic.account).systematics;
    if (account_systematics) {
      account_systematics.push(systematic);
    }
  },
  REMOVE_SYSTEMATIC(state, systematicId) {
    const account_systematics = state.activeClient.systematics.find((item) => item.systematics.some((s) => s.id === systematicId)).systematics;
    const systematic_index = account_systematics.findIndex((s) => s.id === systematicId);
    account_systematics.splice(systematic_index, 1);
  },
  SET_GLOBAL_TRANSFERS(state, transfers) {
    state.transfers = transfers;
  },
  SET_ATON_TRANSFERS(state, transfers) {
    state.atonTransfers = transfers;
  },
  SET_INFO(state, info) {
    state.activeClient.info = info;
  },
  SET_RISK_SCORES(state, scores) {
    scores ? state.activeClient.riskScores = scores : null;
  },
  SET_ADDRESSES(state, addresses) {
    addresses ? state.activeClient.addresses = addresses : null;
  },
  SET_FINANCIALS(state, financials) {
    financials ? state.activeClient.financials = financials : null;
  },
  SET_EMPLOYMENT(state, employment) {
    employment ? state.activeClient.employment = employment : null;
  },
  SET_FUNDS_SOURCES(state, fundsSources) {
    fundsSources ? state.activeClient.fundsSources = fundsSources : null;
  },
  SET_PROFILE_LOADING(state, status) {
    state.activeClient.profileLoading = status;
  },
  UPDATE_ACTIVE_CLIENT_WORKFLOW(state, workflow) {
    let existingWorkflow = state.activeClient.workflows.find((w) => w._id === workflow._id);
    existingWorkflow = workflow;
  },
  SET_DOCUMENTS(state, documents) {
    state.activeClient.documents = documents;
  },
  SET_CORPORATE_ROLES(state, corporate_roles) {
    state.activeClient.corporate_roles = corporate_roles;
  },
  SET_FEE_GROUP(state, feeGroup) {
    state.activeClient.feeGroup = feeGroup;
  },
  SET_REPORTING_GROUP(state, reportingGroup) {
    state.activeClient.reportingGroup = reportingGroup;
  },
  SET_PM_GROUP(state, pmGroup) {
    state.activeClient.pmGroup = pmGroup;
  },
  SET_CLIENT_PM(state, pmInfo) {
    state.activeClient.pmInfo = pmInfo;
  },
  SET_CLIENT_SUBADVISOR(state, subAdvisorInfo) {
    state.activeClient.subAdvisorInfo = subAdvisorInfo;
  },
  SET_IP_INVITE_STATUS(state, status) {
    state.activeClient.IPInviteStatus = status;
  },
  SET_IP_ACCOUNT_STATUS(state, status) {
    state.activeClient.IPAccountStatus = status;
  },
  SET_IP_VERIFICATION_STATUS(state, status) {
    state.activeClient.IPVerificationStatus = status;
  },
  SET_EMAIL_CHANGE_REQUEST_STATUS(state, status) {
    state.activeClient.emailChangeRequestStatus = status;
  },
  SET_TAX_ANS(state, taxAns) {
    state.activeClient.taxAns = taxAns;
  },
  SET_SUITABILITY_SCORE(state, suitabilityScore) {
    state.activeClient.suitabilityScore = suitabilityScore?.results[0];
  },
  SET_ACTIVE_CLIENT_ID_FOR_FATCA_RENEWAL(state, clientId) {
    state.activeClientIdForFATCARenewal = clientId;
  },
  SET_CLIENT_RISK_PROFILE(state, riskProfile) {
    state.activeClient.riskProfile = riskProfile;
  },
};

const actions = {
  // for Client Edit Page
  async updateClient({ dispatch }, data) {
    let url;
    switch (data.client_type) {
      case 'person':
        url = 'persons';
        break;
      case 'corporation':
        url = 'corporations';
        break;
      case 'trust':
        url = 'trusts';
        break;
      case 'foundation':
        url = 'foundations';
        break;
      case 'ipp':
        url = 'ipps';
        break;
      case 'nonprofit':
        url = 'nonprofits';
        break;
      default:
        url = '';
    }
    const updateResponse = await apiV2.post(`/${url}/${data.id}/`, data);
    dispatch('updateUserPhoneNumber', { url, data: updateResponse });
    dispatch('fetchClient', {
      clientID: data.id,
      clientType: data.client_type,
    });
    return updateResponse;
  },
  fetchClient({ commit }, { clientID, clientType }) {
    return api.get(`/datahub/client_all_details/${clientID}`)
      .then((res) => {
        const clientInfo = Object.keys(res).reduce((acc, k) => {
          if (typeof res[k] !== 'object') {
            acc[k] = res[k];
            return acc;
          } return acc;
        }, {});
        commit('SET_CLIENT', res);
        commit('SET_PERSON', Array.isArray(res[clientType]) ? {} : res[clientType]);
      })
      .catch((err) => console.log('err', err));
  },
  async updatePersonClient({ dispatch }, { id, data, refetch = true }) {
    const res = await apiV2.post(`/persons/${id}/`, data);
    if (refetch) dispatch('fetchPersonClient', id);
    return res;
  },
  fetchPersonClient({ commit }, clientID) {
    return api.get(`/datahub/client_all_details/${clientID}`)
      .then((res) => {
        const clientInfo = Object.keys(res).reduce((acc, k) => {
          if (!(typeof res[k] === 'object')) {
            acc[k] = res[k];
            return acc;
          } return acc;
        }, {});
        commit('SET_CLIENT', clientInfo);
        commit('SET_PERSON', Array.isArray(res.person) ? {} : res.person);
      })
      .catch((err) => console.log('err', err));
  },
  createEmployment({ commit }, data) {
    return api.post('/datahub/client_employment/', data)
      .then((res) => {
        res.id ? commit('SET_EMPLOYMENT', res) : null;
        return res;
      })
      .catch((err) => console.log('err', err));
  },
  updateEmployment({ commit }, data) {
    return api.post(`/datahub/client_employment/${data.id}/`, data)
      .then((res) => {
        res.id ? commit('SET_EMPLOYMENT', res) : null;
        return res;
      })
      .catch((err) => console.log('err', err));
  },
  updateAddress({ }, address) {
    return api.post(`/datahub/client_addresses/${address.id}/`, address)
      .then((res) => res)
      .catch((err) => console.log('err', err));
  },
  addAddress({ }, address) {
    delete address.id;
    return api.post('/datahub/client_addresses/', address)
      .then((res) => res)
      .catch((err) => console.log('err', err));
  },
  deleteAddress({ }, address) {
    return api.deleteCall(`/datahub/client_addresses/${address.id}/`)
      .then((res) => res)
      .catch((err) => console.log('err', err));
  },
  setAddresses({ commit, state }) {
    return api.get(`/datahub/client_all_details/${state.activeClient.client.id}`)
      .then((res) => {
        if (res.code !== 500) {
          const addresses = res.ClientAddresses;
          addresses.forEach((address) => {
            // why do we set it to null?
            address.street_suffix = null;
            address.street_direction = null;
          });
          commit('SET_ADDRESSES', addresses);
        }
      }).catch((err) => console.log('err', err));
  },
  createFinancials({ commit }, data) {
    return api.post('/datahub/client_financials/', data)
      .then((res) => {
        res.code === 500 ? null : commit('SET_FINANCIALS', res);
        return res;
      })
      .catch((err) => console.log('err', err));
  },
  updateFinancials({ commit }, data) {
    return api.post(`/datahub/client_financials/${data.financials}/`, data)
      .then((res) => {
        !res.id ? null : commit('SET_FINANCIALS', res);
        return res;
      })
      .catch((err) => console.log('err', err));
  },
  createFundsSources({ commit }, data) {
    return api.post('/datahub/client_funds_source/', data)
      .then((res) => {
        if (res.code !== 500) commit('SET_FUNDS_SOURCES', res);
        return res;
      })
      .catch((err) => console.log('err', err));
  },
  updateFundsSources({ commit }, { id, data }) {
    return api.post(`/datahub/client_funds_source/${id}/`, data)
      .then((res) => {
        if (res.code !== 500) commit('SET_FUNDS_SOURCES', res);
        return res;
      })
      .catch((err) => console.log('err', err));
  },
  verifyEquifaxManually({ }, data) {
    return api.post(`/datahub/set_equifax?client_id=${data.client_id}`, data)
      .then((res) => res)
      .catch((err) => console.log('err', err));
  },
  // Client Document Page
  setDocuments({ commit }, clientID) {
    return api.get(`/datahub/get_documents_by?context=client_id&value=${clientID}`)
      .then((res) => {
        if (res.status === 'success') commit('SET_DOCUMENTS', res.documents);
        return res;
      })
      .catch((err) => console.log('err', err));
  },
  updateDocument({ dispatch }, data) {
    return api.post(`/datahub/documents/${data.id}/`, data)
      .then((res) => {
        dispatch('setDocuments', data.associated_clients[0]);
        return res;
      })
      .catch((err) => console.log('err', err));
  },
  deleteDocument({ dispatch }, { docID, clientID }) {
    return api.deleteCall(`/datahub/documents/${docID}/`)
      .then((res) => {
        dispatch('setDocuments', clientID);
        return res;
      })
      .catch((err) => console.log('err', err));
  },
  // client
  createClient({ }, data) {
    return api.post('/datahub/clients/', data)
      .then((res) => res)
      .catch((err) => console.log('err', err));
  },
  async deleteClient({ }, clientID) {
    try {
      const res = await api.post('/advisor_portal/reset_ip_registration', { client_id: clientID });
      if (res.code === 500) {
        throw new Error('Error resetting IP registration');
      }
      await api.deleteCall(`/datahub/clients/${clientID}/`);
    } catch (err) {
      console.error('err', err);
      Promise.reject(err);
    }
  },
  fetchClientByID({ }, clientID) {
    return api.get(`/datahub/clients/${clientID}/`)
      .then((res) => res)
      .catch((err) => { console.log('err', err); });
  },
  setActiveClientIdForFATCARenewal({ commit }, clientID) {
    commit('SET_ACTIVE_CLIENT_ID_FOR_FATCA_RENEWAL', clientID)
  },
  getClientFromClientsList({ commit, state }, clientID) {
    const client = state.list.find((client) => client.id === clientID);
    commit('SET_CLIENT', client);
  },
  fetchClientDetails({ commit, dispatch }, clientID) {
    if (!clientID) {
      console.log('No client ID to retrieve details with.');
      return;
    }
    return Promise.all([
      api.get(`/datahub/client_all_details/${clientID}`),
      dispatch('getInvestmentAccounts', clientID),
      dispatch('fetchTransfersByClientID', clientID),
      api.get(`/datahub/clients/${clientID}/client_funds_source/`),
      api.get(`/datahub/get_documents_by?context=client_id&value=${clientID}`),
      api.get(`/datahub/get_rrifpmt_by?context=client_id&value=${clientID}`),
      apiV2.get(`/users/v1/users/?client=${clientID}&roles=portfolio_manager`, undefined, undefined, axonUrl),
      dispatch('setFeeGroup', clientID),
      dispatch('setReportingGroup', clientID),
      dispatch('setPMGroup', clientID),
      dispatch('setClientIPInvitationStatus', clientID),
      dispatch('setClientIPAccountStatus', clientID),
      dispatch('setEmailChangeRequestStatus', clientID),
      dispatch('setClientTaxAns', clientID),
      dispatch('setClientSuitabilityScore', clientID),
      dispatch('setClientRiskProfile', clientID),
    ])
      .then((responses) => {
        const allDetails = responses[0];
        if (allDetails?.code === 403) {
          dispatch('setSnackbar', { text: 'Unable to retrieve client details due to permissions.', type: 'error' }, { root: true });
          dispatch('flipSnackbarSwitch', true, { root: true });
        } else if (allDetails?.code >= 400) {
          dispatch('setSnackbar', { text: 'Unable to retrieve client details.', type: 'error' }, { root: true });
          dispatch('flipSnackbarSwitch', true, { root: true });
        }
        const clientInfo = Object.keys(allDetails).reduce((acc, k) => {
          if (!(typeof allDetails[k] === 'object') || !allDetails[k]) {
            acc[k] = allDetails[k];
            return acc;
          } return acc;
        }, {});
        commit('SET_CLIENT', clientInfo);
        switch (allDetails.client_type) {
          case 'person':
            commit('SET_PERSON', Array.isArray(allDetails.person) ? {} : allDetails.person);
            break;
          case 'corporation':
            commit('SET_CORPORATION', Array.isArray(allDetails.corporation) ? {} : allDetails.corporation);
            break;
          case 'foundation':
            commit('SET_FOUNDATION', Array.isArray(allDetails.foundation) ? {} : allDetails.foundation);
            break;
          case 'trust':
            commit('SET_TRUST', Array.isArray(allDetails.trust) ? {} : allDetails.trust);
            break;
          case 'nonprofit':
            commit('SET_NON_PROFIT', Array.isArray(allDetails.nonprofit) ? {} : allDetails.nonprofit);
            break;
          case 'ipp':
            commit('SET_IPP', Array.isArray(allDetails.ipp) ? {} : allDetails.ipp);
            break;
        }
        commit('SET_RISK_SCORES', allDetails.RiskScores ? allDetails.RiskScores.sort((a, b) => (a.created_at < b.created_at ? 1 : -1))[0] : {});
        // employment
        let employment = allDetails.ClientEmployment ? allDetails.ClientEmployment : [];
        employment = employment.sort((a, b) => { a.created_at < b.created_at ? -1 : 1; })[0];
        commit('SET_EMPLOYMENT', employment);

        const corporateRoles = allDetails.CorporateRoles;
        commit('SET_CORPORATE_ROLES', corporateRoles);

        const addresses = allDetails.ClientAddresses;
        addresses.forEach((address) => {
          address.street_suffix = null;
          address.street_direction = null;
        });
        commit('SET_ADDRESSES', addresses);

        if (allDetails.ClientFinancials && allDetails.ClientFinancials[allDetails.ClientFinancials.length - 1]) {
          const clientFinancials = allDetails.ClientFinancials[allDetails.ClientFinancials.length - 1];
          const financials = Object.keys(clientFinancials)
            .reduce((acc, key) => {
              key !== 'income_source' ? acc[key] = Number(clientFinancials[key]) : acc[key] = clientFinancials[key];
              return acc;
            }, {});
          commit('SET_FINANCIALS', financials);
        }

        commit('SET_BANK_ACCOUNTS', allDetails.BankAccounts ? allDetails.BankAccounts : []);
        if (allDetails.ExternalBankingClientRelationships && allDetails.ExternalBankingClientRelationships.length > 0) {
          const externalBankAccounts = allDetails.ExternalBankingClientRelationships.map((acc) => {
            return {
              id: acc.bank_account_info?.id,
              institution_no: acc.bank_account_info?.institution_no,
              transit_no: acc.bank_account_info?.transit_no,
              account_no: acc.bank_account_info?.account_no,
              description: acc.bank_account_info?.description,
              client: acc.client,
              status: 'verified',
              external: true,
            };
          });
          commit('UPDATE_BANK_ACCOUNTS', externalBankAccounts);
        }
        commit('SET_FUNDS_SOURCES', responses.length > 3 && responses[3] ? responses[3].client_funds_source[0] : {});
        dispatch('fetchAccountValues').then((res) => res).catch((err) => console.log('err', err));
        commit('SET_DOCUMENTS', responses[4].documents);
        // filter out deleted payments
        commit('SET_RRIF_PAYMENTS', responses[5].rrif_pmt && responses[5].rrif_pmt.length !== 0 ? responses[5].rrif_pmt : []);
        if (responses[6]?.result[0]) { // We have client's pm info
          commit('SET_CLIENT_PM', responses[6]?.result[0]);
        }
      }).catch((err) => console.log('err', err));
  },
  async setFeeGroup({ commit, dispatch }, clientID) {
    try {
      const feeGroup = await fetchFeeGroup(clientID);
      commit('SET_FEE_GROUP', !feeGroup ? {} : feeGroup);
      if (feeGroup.planning_schedule) dispatch('setPlanningSchedule', feeGroup.planning_schedule);
      if (feeGroup.management_schedule) dispatch('setManagementSchedule', feeGroup.management_schedule);
      if (feeGroup.subadvisor_schedule) dispatch('setSubadvisorSchedule', feeGroup.subadvisor_schedule);
    } catch (err) {
      return console.log('err', err);
    }
  },
  async setClientSuitabilityScore({ commit }, clientID) {
    fetchClientPortfolio(clientID)
      .then(async (res) => {
        commit('SET_SUITABILITY_SCORE', res);
      })
      .catch((err) => err);
  },
  async setClientRiskProfile({ commit }, clientID) {
    // fetching from datahub_client_risk_profile table
    fetchClientRiskProfile(clientID)
      .then((res) => {
        commit('SET_CLIENT_RISK_PROFILE', res.results[0]);
      })
      .catch((err) => err);
  },
  setClientIPInvitationStatus({ commit, dispatch }, clientID) {
    fetchClientIPInviteStatus(clientID)
      .then(async (res) => {
        if (res.status && res.status.id) {
          commit('SET_IP_INVITE_STATUS', res.status.id);
          if (res.status.id === 2) {
            await dispatch('fetchIPVerificationStatus', clientID);
          }
        } else {
          commit('SET_IP_INVITE_STATUS', null);
        }
      })
      .catch((err) => err);
  },
  setClientIPAccountStatus({ commit }, clientID) {
    // TODO: deprecate this ideally and use IP as single source of truth
    return fetchInvestorAccountStatus(clientID)
      .then((res) => {
        if (res.client) {
          commit('SET_IP_ACCOUNT_STATUS', res);
        }
      })
      .catch((err) => err);
  },
  fetchIPVerificationStatus({ commit }, clientId) {
    return fetchVerificationStatus({ clientId })
      .then((res) => {
        commit('SET_IP_VERIFICATION_STATUS', res);
      })
      .catch((err) => err);
  },
  setEmailChangeRequestStatus({ commit }, clientID) {
    return fetchEmailChangeRequest(clientID)
      .then((res) => {
        if (res.status) {
          commit('SET_EMAIL_CHANGE_REQUEST_STATUS', res);
        }
      })
      .catch((err) => err);
  },
  setClientTaxAns({ commit }, clientID) {
    return fetchClientTaxAns(clientID)
      .then((res) => {
        commit('SET_TAX_ANS', res);
      })
      .catch((err) => err);
  },
  updateClientTaxAns({ commit }, data) {
    return apiV2.post('/client_tax_confirmation_answers/', data)
      .then((res) => {
        commit('SET_TAX_ANS', res);
      })
      .catch((err) => err);
  },
  setReportingGroup({ commit }, clientID) {
    fetchClientReportingGroup(clientID)
      .then((res) => {
        commit('SET_REPORTING_GROUP', res.status === 'success' ? res.result : {});
      })
      .catch((err) => err);
  },
  setReportingGroup({ commit }, clientID) {
    fetchClientReportingGroup(clientID)
      .then((res) => {
        commit('SET_REPORTING_GROUP', res.status === 'success' ? res.result : {});
      })
      .catch((err) => err);
  },
  setPMGroup({ commit }, clientID) {
    fetchClientPMGroup(clientID)
      .then((res) => commit('SET_PM_GROUP', res.status === 'success' ? res.result : {}))
      .catch((err) => err);
  },
  resetActiveClient({ commit }) {
    commit('RESET_ACTIVE_CLIENT');
  },
  // clients
  fetchClientsWithPermissions({ dispatch, rootState }) {
    const permissions = rootState.users.currentUserRepCodes;
    if (!permissions || permissions.length === 0) {
      dispatch('setClients', _.cloneDeep(defaultState.list));
    }
    return api.get('/datahub/clients_filter')
      .then((res) => {
        const clientsArray = res.reduce((acc, el) => acc.concat(el), []); // 2D flatten
        const clientsRef = clientsArray.reduce((acc, el) => {
          if (acc[el.id]) return acc;
          acc[el.id] = el;
          return acc;
        }, {});
        const clients = Object.values(clientsRef);
        dispatch('setClients', clients);
        return clients;
      })
      .catch((err) => console.log('err', err));
  },
  setClients({ commit }, clients) {
    commit('SET_CLIENTS', clients);
  },
  // investment accounts
  getInvestmentAccounts({ commit, dispatch }, clientID) {
    return api.get(`/datahub/clients/${clientID}/accounts/`)
      .then(({ accounts }) => {
        accounts === undefined ? commit('SET_INVESTMENT_ACCOUNTS', []) : commit('SET_INVESTMENT_ACCOUNTS', accounts);
        dispatch('fetchAccountValues')
          .then((res) => res)
          .catch((err) => { console.log('err in fetch account values', err); });
      })
      .catch((err) => commit('SET_INVESTMENT_ACCOUNTS', []));
  },
  deleteInvestmentAccount({ state, dispatch }, accountId) {
    deleteAccount(accountId)
      .then((success) => {
        if (!success) { return; }
        dispatch('getInvestmentAccounts', state.activeClient.client.id);
      });
  },
  updateInvestmentAccount({ dispatch }, { accountID, data }) {
    return api.patch(`/datahub/accounts/${accountID}/`, data)
      .then((res) => dispatch('getInvestmentAccounts', data.primary_client_id))
      .catch((err) => console.log('err', err));
  },
  getRrifPayments({ commit }, accountID) {
    return api.get(`/datahub/get_rrifpmt_by?context=account_id&value=${accountID}`)
      .then((res) => res)
      .catch((err) => console.log('err', err));
  },
  fetchTotalAUM({ commit }, clientID) {
    return api.get(
      '/datahub/get_total_market_value',
      'context=client',
      `value=${clientID}`,
      'currency=CAD',
    )
      .then((response) => {
        const holdings = response?.requested_payload?.clients ? response.requested_payload.clients[0].holdings : [0];
        if (!holdings) {
          commit('SET_TOTAL_AUM', 0);
          return 0;
        }
        const total_aum = holdings[0].total_value;
        commit('SET_TOTAL_AUM', total_aum);
        return total_aum;
      })
      .catch((err) => console.log('err', err));
  },
  fetchAccountValues({ commit, state }) {
    // looks at activeClient's investment accounts and fetches values for them
    return api.get(
      '/datahub/get_total_market_value',

      'context=account',
      // value: ia.id,
      `value=${state.activeClient.investment_accounts.map((ia) => ia.id).join(',')
      }`,
    )
      .then((response) => {
        if (!(response.status === 'success')) {
          commit('SET_ACCOUNT_VALUES', []);
          return;
        }
        const { accounts } = response.requested_payload;
        commit('SET_ACCOUNT_VALUES', accounts.map((a) => ({
          id: a.id,
          value: a.holdings.length ? a.holdings[0].total_value : 0,
        })));
        commit('ADD_ACCOUNT_VALUES_TO_ACCOUNTS');
        return null;
      })
      .catch((err) => console.log('err', err));
  },
  // systematics
  fetchSystematics({ commit }, clientID) {
    return api.get(
      '/datahub/get_systematics_by',
      'pull_by=client_id',
      `value=${clientID}`,
    )
      .then((res) => {
        if (res.code === 500) {
          commit('SET_SYSTEMATICS', []);
          return { success: false };
        }
        let systematics = res;
        systematics = systematics.map((s) => {
          if (!Array.isArray(s.systematics)) {
            s.systematics = [];
          }
          return s;
        });
        commit('SET_SYSTEMATICS', systematics);
        return { success: true };
      })
      .catch((err) => console.log('err', err));
  },
  fetchPositions({ }, { context, value, security_classes }) {
    return api.get(
      '/datahub/get_positions_by',
      `context=${context || ''}`,
      `value=${value || ''}`,
      `security_class_filter=${security_classes || ''}`,
    )
      .then((res) => res)
      .catch((err) => console.log('err', err));
  },
  createSystematic({ dispatch }, systematic) { // creates in DH then adds to state
    return api.post('/datahub/systematics_pas/', systematic)
      .then((res) => {
        if (!res.code) dispatch('addSystematic', res);
        return res;
      })
      .catch((err) => {
        console.log('err', err);
        return { error: err };
      });
  },
  addSystematic({ commit }, systematic) { // adds to state
    commit('ADD_SYSTEMATIC', systematic);
  },
  deleteSystematic({ commit }, systematicId) { // deletes in DH then removes from state
    return api.deleteCall(`/datahub/systematics_pas/${systematicId}/`)
      .then((res) => {
        if (!res) throw Error;
        commit('REMOVE_SYSTEMATIC', systematicId);
      })
      .catch((err) => {
        console.log('err', err);
        return { error: err };
      });
  },
  // transfers
  getAtonTransfers({ dispatch, rootState }) {
    const permissions = rootState.users.currentUserRepCodes;
    if (!permissions || !permissions.length) {
      dispatch('setClients', _.cloneDeep(defaultState.list));
    }
    const currentUserRepCodes = permissions.join(',');
    return api.get(`/datahub/get_aton_transfers/?context=rep_code&value=${currentUserRepCodes}`)
      .then((res) => {
        dispatch('setAtonTransfers', res.status === 'success' ? res.aton_transfers : []);
      })
      .catch((err) => console.log('error getting aton transfers: ', err));
  },

  setAtonTransfers({ commit }, transfers) {
    // Normalizing these to be the same as the transfer
    const normalizedTransfers = transfers.map((x) => ({
      account_holder: x.rft_id ? `${x.first_name} ${x.last_name}` : null,
      relinquishing_account: x.d_acct,
      relinquishing_institution: x.relinquishing_institution,
      destination_account: x.account_no_dataphile,
      amount_estimated: parseInt(x.total_value),
      date: x.open_dt || moment(x.open_dt),
      transfer_method: ATON_TRANSFER.METHODS[x.cash_kind],
      transfer_status: x.rft_status,
      client_id: x.client_id || null,
    }));

    normalizedTransfers.sort((a, b) => ((b.date ? b.date.valueOf() : 0) - (a.date ? a.date.valueOf() : 0)));
    console.log('normalizedTransfers: ', normalizedTransfers);
    commit('SET_ATON_TRANSFERS', normalizedTransfers);
  },

  fetchTransfersByClientID({ commit }, clientID) {
    return api.get(`/datahub/eat_by_client_id/${clientID}`)
      .then((res) => commit('SET_TRANSFERS', res.transfers))
      .catch((err) => console.log('err', err));
  },
  // bank accounts
  getBankAccounts({ commit }, clientID) {
    api.get(`/datahub/clients/${clientID}/banking_info`)
      .then((userWithBankingInfo) => commit('SET_BANK_ACCOUNTS', userWithBankingInfo.banking_info))
      .catch((err) => console.log('err', err));
  },
  addBankAccount({ dispatch }, data) {
    return api.post('/datahub/client_banking_Info/', data)
      .then((res) => {
        dispatch('getBankAccounts', data.client);
        dispatch('setDocuments', data.client);
        return res;
      })
      .catch((err) => console.log('err', err));
  },
  updateBankAccounts({ dispatch }, { accountID, data }) {
    return api.post(`/datahub/bank_accounts/${accountID}/`, data)
      .then((res) => {
        dispatch('getBankAccounts', data.client);
        dispatch('setDocuments', data.client);
        return res;
      })
      .catch((err) => console.log('err', err));
  },
  deleteBankAccounts({ dispatch }, data) {
    return api.post(`/datahub/bank_accounts/${data.id}/`, { status: 4 })
      .then((res) => {
        if (res) {
          dispatch('getBankAccounts', data.client);
          if (data.document) {
            dispatch('deleteDocument', {
              docID: data.document,
              clientID: data.client,
            });
          }
        }
        return res;
      })
      .catch((err) => console.log('err', err));
  },
  validateBankAccount({ }, transitNumberInstitutionNumber) {
    return api.get(`/datahub/eft_bank_info/${transitNumberInstitutionNumber}/`)
      .then((res) => (!!res.id))
      .catch((err) => {
        console.log('err', err);
        return false;
      });
  },
  deleteAvatar({ }, id) {
    return api.deleteCall(`/datahub/clients/${id}/avatar/`)
      .then((res) => (res ? 'success' : null))
      .catch((err) => console.log('err', err));
  },
  // relationships
  fetchRelationships({ commit }, clientID) {
    return api.get(`/datahub/clients/${clientID}/relationships`)
      .then((res) => {
        commit('SET_RELATIONSHIPS', res.relationships ? res.relationships : []);
        return res.relationships;
      })
      .catch((err) => console.log('err', err));
  },
  fetchRelationship({ }, relationshipID) {
    return api.get(`/datahub/relationships/${relationshipID}/`)
      .then((res) => res)
      .catch((err) => console.log('err', err));
  },
  createRelationshipPair({ }, pair) {
    return Promise.all([
      api.post('/datahub/relationships/', pair[0]), // primary
      api.post('/datahub/relationships/', pair[1]),
    ])
      .then((responses) => responses)
      .catch((err) => console.log('err', err));
  },
  deleteRelationshipPair({ }, pair) {
    return Promise.all([
      api.deleteCall(`/datahub/relationships/${pair[0]}/`),
      api.deleteCall(`/datahub/relationships/${pair[1]}/`),
    ])
      .then((res) => res)
      .catch((err) => console.log('err', err));
  },
  editRelationshipPair({ }, pair) {
    return Promise.all([
      api.post(`/datahub/relationships/${pair[0].id}/`, pair[0].obj),
      api.post(`/datahub/relationships/${pair[1].id}/`, pair[1].obj),
    ])
      .then((responses) => responses)
      .catch((err) => console.log('err', err));
  },
  fetchCorporateRolesForClient({ commit }, clientID) {
    return api.get(`/datahub/get_corporate_roles/?context=client_id&value=${clientID}`)
      .then((res) => {
        const roles = !res.resp_info ? [] : res.resp_info;
        commit('SET_CORPORATE_ROLES', roles);
        return res;
      })
      .catch((err) => console.log('err', err));
  },
  fetchCorporateRoles({ commit }, clientID) {
    return api.get(`/datahub/get_corporate_roles/?context=corporate_client_id&value=${clientID}`)
      .then((res) => {
        const roles = !res.resp_info ? [] : res.resp_info;
        commit('SET_CORPORATE_ROLES', roles);
        return res;
      })
      .catch((err) => console.log('err', err));
  },
  addCorporateRole({ }, data) {
    return api.post('/datahub/corporate_roles/', data)
      .then((res) => res)
      .catch((err) => console.log('err', err));
  },
  updateCorporateRole({ }, data) {
    return api.post(`/datahub/corporate_roles/${data.id}/`, data)
      .then((res) => res)
      .catch((err) => console.log('err', err));
  },
  deleteCorporateRole({ }, id) {
    return api.deleteCall(`/datahub/corporate_roles/${id}/`)
      .then((res) => res)
      .catch((err) => console.log('err', err));
  },
  addKycDetails({ }, data) {
    return api.post('/datahub/kyc_details/', data)
      .then((res) => res)
      .catch((err) => console.log('err', err));
  },
  addKycAmlDetails({ }, data) {
    return api.post('/datahub/kyc_aml_details/', data)
      .then((res) => res)
      .catch((err) => console.log('err', err));
  },
  // workflows
  getWorkflows({ commit, rootState }, clientID) {
    if (!rootState.app.features.new_workflows) {
      return api.get('/advisor_portal/workflow/get', `pas_client_ids=${clientID}`, 'limit=1000')
        .then((res) => {
          commit('SET_WORKFLOWS', res.docs);
          return res.docs;
        })
        .catch((err) => console.log('err', err));
    }
    return fetchWorkflowsForClient(clientID, 5).then((workflows) => {
      commit('SET_WORKFLOWS', workflows.workflows);
      commit('SET_TOTAL_WORKFLOWS', workflows.totalCount);
    });
  },
  reassignWorkflow({ commit }, { workflow, userID }) {
    return api.post('/advisor_portal/workflow/put/', {
      workflow: workflow._id,
      action: 'assign_user',
      user: userID,
    })
      .then((res) => {
        if (res.status && res.status.code === 200) {
          workflow.assigned_user = userID;
          workflow.assigned_user_name = res.result.username;
          commit('UPDATE_ACTIVE_CLIENT_WORKFLOW', workflow);
          return { ...workflow, error: false };
        }
        console.log('error response', res);
        return { error: true, res };
      })
      .catch((err) => console.log('err', err));
  },
  createNewWorkflow({ }, { pas_client_ids, workflow_type }) {
    return api.post('/advisor_portal/workflow/new/', {
      pas_client_ids, workflow_type,
    });
  },
  // risk profile
  createRiskScores({ commit }, data) {
    return api.post('/datahub/risk_scores/', data)
      .then((res) => {
        res.id ? commit('SET_RISK_SCORES', res) : null;
        return res;
      })
      .catch((err) => console.log('err', err));
  },
  updateRiskScores({ commit }, data) {
    return api.post(`/datahub/risk_scores/${data.id}/`, data)
      .then((res) => {
        res.id ? commit('SET_RISK_SCORES', res) : null;
        return res;
      })
      .catch((err) => console.log('err', err));
  },
  updateUserPhoneNumber({ commit }, { url, data }) {
    if (url != 'persons') return;
    if ((!data.home_number && !data.mobile_number) || !data.id || !data.firm_id) return;

    const phoneNumber = data.mobile_number || data.home_number;
    const client_id = data.id;
    const firm = data.firm_id;

    return api.post('/advisor_portal/user_update_phone_number', { mobile_number: phoneNumber, client_id, firm });
  },
};

export const getters = {
  investmentAccountsWithSystematics: (state) => {
    if (state.activeClient.investment_accounts.length === 0) return [];
    const investment_accounts_clone = _.cloneDeep(state.activeClient.investment_accounts);
    const iaws = investment_accounts_clone.map((a) => {
      a.systematics = state.activeClient.systematics.find((s) => s.account_info.id === a.id);
      if (a.systematics) a.systematics = a.systematics.systematics;
      if (Array.isArray(a.systematics)) {
        a.systematics.sort((a, b) => (a.start_date < b.start_date ? 1 : -1));
      }
      return a;
    });
    return iaws;
  },
  clientList: (state) => {
    const clientList = {};
    state.list.forEach((client) => {
      let {
        id, display_name, client_status, client_type, email,
      } = client;
      client_status = CLIENTSTATUS[client_status];
      clientList[id] = {
        id, display_name, client_status, client_type, email,
      };
    });
    return clientList;
  },
  clientId: (state) => state.activeClient.client.id,
  isClientStatusActive: (state) => {
    const status = state.activeClient.client && state.activeClient.client.client_status && state.activeClient.client.client_status === 'active';
    return status;
  },
  activeClient: (state) => state.activeClient,
  feeGroup: (state) => state.activeClient.feeGroup,
  hasSubmittedKycChangeRequest: (state) => {
    if (state.activeClient.workflows && state.activeClient.workflows.length > 0) {
      return state.activeClient.workflows.find((wf) => (
        ['client_maintenance', 'annual_kyc'].includes(wf.workflowType)
        && wf.workflowStatus === 'in_progress'
        && wf.steps[0] && wf.steps[0].stepName !== 'change_request')); // since change_request is the first step, if it's not the first active step, then it's completed.
    }
    return false;
  },
  isPersonClient: (state) => state.activeClient.client.client_type === 'person',
};

const modules = {
  accounts,
};

export default {
  state,
  mutations,
  actions,
  getters,
  modules,
};

// helpers
