<template>
  <div class="navbar-menu">
    <v-toolbar light :class="headerColour">
      <div :class="`${theme} mr-4 logo`" @click="$router.push({ path: '/' })">
        <v-img :src="logo" height="40" contain />
      </div>

      <!-- DESKTOP VIEW BUTTONS-->
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn v-if="canViewMyClientsBasicInformation" flat class="menu-item-btn">
          <router-link class="menu-item-text" to="/households">Households &#38; Clients</router-link>
        </v-btn>
        <v-btn flat class="menu-item-btn">
          <router-link class="menu-item-text" to="/workflows">Workflows</router-link>
        </v-btn>
        <v-menu offset-y right>
          <v-btn flat class="menu-item-btn" slot="activator">
            <div :class="['menu-item-text', { selected: myViews.includes(url) }]">Views</div>
            <v-icon size="20" class="grey-text">expand_more</v-icon>
          </v-btn>
          <v-list class="py-5">
            <v-list-tile v-if="transfersFeatureEnabled">
              <v-btn flat class="menu-item-btn">
                <router-link
                  :class="['sub-menu-text', { selected: url === '/transfers' }]"
                  to="/transfers"
                >Transfers</router-link>
              </v-btn>
            </v-list-tile>
            <v-list-tile v-if="isMoneyMovementFeature && canViewMoneyMovementConsolidatedView">
              <v-btn flat class="menu-item-btn">
                <router-link
                  :class="['sub-menu-text', { selected: url === '/money-movements' }]"
                  to="/money-movements"
                >Money Movements</router-link>
              </v-btn>
            </v-list-tile>
            <v-list-tile v-if="canViewPortfolioDetails && modelPortfolioDetailsFeatureEnabled">
              <v-btn flat class="menu-item-btn">
                <router-link
                  :class="['sub-menu-text', { selected: url === '/portfolio_details' }]"
                  to="/portfolio_details"
                >Portfolio Details</router-link>
              </v-btn>
            </v-list-tile>
            <v-list-tile v-if="d1g1tSsoEnabled">
              <v-btn flat class="menu-item-btn">
                <a
                  :href="d1g1tSsoUrl"
                  :class="['sub-menu-text', { selected: url === d1g1tSsoUrl }]"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  D1g1t Platform
                </a>
              </v-btn>
            </v-list-tile>
            <v-list-tile v-if="isInsightsOn"> <!-- Add user level permission later -->
              <v-btn flat class="menu-item-btn">
                <router-link
                  :class="['sub-menu-text', { selected: url === '/insights' }]"
                  to="/insights"
                >Insights</router-link>
              </v-btn>
            </v-list-tile>
          </v-list>
        </v-menu>
        <v-menu
          offset-y
          right
          v-if="(canEditPlatformUsersManager || canEditRolesAndPermissionsManager || canEditRAPreferences) && rolesAndPermissionsFeatureEnabled"
        >
          <v-btn flat class="menu-item-btn" slot="activator">
            <div
              :class="['menu-item-text', { selected: userManagement.includes(url) }]"
            >User Management</div>
            <v-icon size="20" class="grey-text">expand_more</v-icon>
          </v-btn>
          <v-list class="py-5">
            <v-list-tile v-if="canEditRolesAndPermissionsManager">
              <v-btn flat class="menu-item-btn">
                <router-link
                  :class="['sub-menu-text', { selected: url === '/roles-and-permissions-manager' }]"
                  to="/roles-and-permissions-manager"
                >Roles and Permissions</router-link>
              </v-btn>
            </v-list-tile>
            <v-list-tile v-if="canEditPlatformUsersManager">
              <v-btn flat class="menu-item-btn">
                <router-link
                  :class="['sub-menu-text', { selected: url === '/platform-users-manager' }]"
                  to="/platform-users-manager"
                >Platform Users</router-link>
              </v-btn>
            </v-list-tile>
            <v-list-tile v-if="canEditRAPreferences && isRAPreferencesOn">
              <v-btn flat class="menu-item-btn">
                <router-link
                  :class="['sub-menu-text', { selected: url === '/ra-preferences-manager' }]"
                  to="/ra-preferences-manager"
                >RA Preferences</router-link>
              </v-btn>
            </v-list-tile>
          </v-list>
        </v-menu>
        <v-menu
          offset-y
          right
          v-if="FATCARenewalsFeatureEnabled"
        >
          <v-btn flat class="menu-item-btn" slot="activator">
            <div
              :class="['menu-item-text', { selected: userManagement.includes(url) }]"
            >Discovery</div>
            <v-icon size="20" class="grey-text">expand_more</v-icon>
          </v-btn>
          <v-list class="py-5">
            <v-list-tile v-if="isRegulationsOn && canViewRegulationsPage">
              <v-btn flat class="menu-item-btn">
                <router-link
                  :class="['sub-menu-text', { selected: url === '/regulations' }]"
                  to="/regulations"
                >Client KYC</router-link>
              </v-btn>
            </v-list-tile>
            <v-list-tile>
              <v-btn flat class="menu-item-btn">
                <router-link
                  :class="['sub-menu-text', { selected: url === '/fatca-renewals' }]"
                  to="/fatca-renewals"
                >FATCA Renewals</router-link>
              </v-btn>
            </v-list-tile>
          </v-list>
        </v-menu>
        <v-btn v-if="isRegulationsOn && canViewRegulationsPage && !FATCARenewalsFeatureEnabled" flat class="menu-item-btn">
          <router-link class="menu-item-text" to="/regulations">Discovery</router-link>
        </v-btn>
      </v-toolbar-items>

      <v-spacer />

      <v-toolbar-items class="hidden-sm-and-down">

        <v-menu offset-y right>
          <v-btn slot="activator" light flat>
            <div :class="['menu-item-text']">Account</div>
            <v-icon size="20" class="grey-text">expand_more</v-icon>
          </v-btn>
          <v-list class="py-5">
            <v-list-tile v-if="isHelpCenterOn && helpCenterLink" :href='helpCenterLink' target="_blank">
              <v-list-tile-title class="sub-menu-text">Help</v-list-tile-title>
            </v-list-tile>
            <v-list-tile @click="$router.push({ path: '/settings' })">
              <v-list-tile-title class="sub-menu-text">Settings</v-list-tile-title>
            </v-list-tile>
            <v-list-tile @click="logout">
              <v-list-tile-title>
                <v-list-tile-title class="sub-menu-text">Logout</v-list-tile-title>
              </v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>
      </v-toolbar-items>


      <!-- TODO: MOBILE VIEW BUTTON-->
    </v-toolbar>

  </div>
</template>

<script>
import { fetchModules } from 'Services/api/RolesAndPermissions';
import { getMetadata } from 'Services/api/metadata';
import { getAvatar } from 'Services/helpers/files';
import { mapGetters } from 'vuex';
import { D1G1T_INTEGRATION_DATA } from '../services/constants';

export default {
  name: 'HeaderNavV2',
  data() {
    return {
      getAvatar,
      menuDialog: false,
      menuItems: [
        { title: 'Workflows', link: '/' },
        { title: 'Households & Clients', link: '/clients' },
        {
          title: 'Transfers',
          link: '/transfers',
          canAccess: () => this.transfersFeatureEnabled,
        },
        { title: 'Workflows', link: '/workflows' },
        {
          title: 'Roles and Permissions Manager',
          link: '/roles-and-permissions-manager',
          canAccess: () => this.canEditRolesAndPermissionsManager
            && this.rolesAndPermissionsFeatureEnabled,
        },
        {
          title: 'Platform Users Manager',
          link: '/platform-users-manager',
          canAccess: () => this.canEditPlatformUsersManager
            && this.rolesAndPermissionsFeatureEnabled,
        },
        {
          title: 'Discovery',
          link: '/regulations',
          canAccess: () => this.canViewRegulationsPage
            && this.isRegulationsOn,
        },
      ],
      helpCenterLink: '',
    };
  },
  async mounted() {
    // this.url = this.$router.currentRoute.fullPath;
    const hcLink = await getMetadata('help_center_link');
    if (hcLink) {
      this.helpCenterLink = hcLink;
    }
    this.allModules = await fetchModules();
  },
  computed: {
    ...mapGetters([
      'canViewMyClientsBasicInformation',
      'canViewPortfolioDetails',
      'canEditRolesAndPermissionsManager',
      'canEditPlatformUsersManager',
      'canViewMoneyMovementConsolidatedView',
      'canViewFundingPage',
      'canViewRegulationsPage',
      'canEditRAPreferences',
      'hasAccessToInsights',
      'hasFeatureFlag',
    ]),
    env() {
      return this.$store.state.app.env;
    },
    d1g1tFirmData() {
      return D1G1T_INTEGRATION_DATA[this.$store.getters.firm] || {};
    },
    d1g1tFirmPrefix() {
      return this.d1g1tFirmData.d1g1tPrefix || '';
    },
    d1g1tSsoEnabled() {
      return this.d1g1tFirmData.ssoEnabled || false;
    },
    d1g1tSsoUrl() {
      return `https:////api-${this.d1g1tFirmPrefix}.${localStorage.getItem('d1g1tUrlDomain', '')}/api/v1/auth/saml2/login/`;
    },
    url() {
      return this.$router.currentRoute.fullPath;
    },
    isRAPreferencesOn() {
      return this.hasFeatureFlag('ra_preferences');
    },
    isFundingOn() {
      return this.hasFeatureFlag('funding_page');
    },
    isFundingV2On() {
      return this.hasFeatureFlag('funding_v2');
    },
    isHelpCenterOn() {
      return this.hasFeatureFlag('help_center');
    },
    isRegulationsOn() {
      return this.hasFeatureFlag('annual_kyc');
    },
    isInsightsOn() {
      return this.hasFeatureFlag('insights') && this.hasAccessToInsights;
    },
    isMoneyMovementFeature() {
      return this.hasFeatureFlag('money_movement');
    },
    currentUser() {
      return this.$store.state.users.currentUser;
    },
    headerColour() {
      return this.env === 'dev' ? 'teal lighten-3' : 'white';
    },
    logo() {
      return this.$store.state.app.themeAssets.logo;
    },
    theme() {
      return this.$store.state.app.theme;
    },
    myViews() {
      return ['/workflows', '/transfers'];
    },
    userManagement() {
      return ['/roles-and-permissions-manager', '/platform-users-manager'];
    },
    rolesAndPermissionsFeatureEnabled() {
      return this.hasFeatureFlag('roles_and_permissions');
    },
    modelPortfolioDetailsFeatureEnabled() {
      return this.hasFeatureFlag('model_portfolio_details');
    },
    transfersFeatureEnabled() {
      return this.hasFeatureFlag('aton_transfers');
    },
    FATCARenewalsFeatureEnabled() {
      return this.hasFeatureFlag('fatca_renewals');
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('logUserOut');
      this.$router.push({ name: 'login' });
    },
  },
};
</script>

<style lang="scss">

.navbar-menu {
  @import "~Styles/vuetify.scss";
  color: rgba(0,0,0,.87) !important;
  font-size: 13px !important;
  font-weight: 300 !important;
  letter-spacing: 0px !important;
  box-shadow: none;
  font-family: "AvenirLTPro-Medium",sans-serif !important;
  // @import '../stylus/main.styl';
  a:hover {
    text-decoration: none;
  }
  button {
    letter-spacing: 0px !important;
    background-color: none !important;
    border-style: none !important;
  }
}

.v-toolbar {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1) !important;
}

.logo {
  width: 130px !important;
  cursor: pointer !important;
}

// this later should probably be in the theme variables
.logo.milestone {
  width: 230px !important;
}

.logo.samara {
  padding: 12px;
}

.menu-item-btn:hover:before,
.menu-item-btn:hover:after {
  background-color: transparent !important;
}

.menu-item-text {
  text-transform: none;
  color: rgb(50, 55, 65);
  &:hover {
    color: var(--primary-color) !important;
  }
}

.grey-text {
  color: #757575 !important;
}

.sub-menu-text {
  color: #757575 !important;
  text-transform: none;
  &:hover {
    color: var(--primary-color) !important;
  }
}

.selected {
  color: var(--primary-color) !important;
}

.mobile-menu-btn {
  width: 40px;
  min-width: 40px;
}
.mobile-menu-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.v-menu__content {
  z-index: 999 !important;
}

.v-toolbar__title {
  font-size: 18px !important;
  margin-left: 0px !important;
  padding-right: 24px !important;
}

.v-menu__content .v-list {
  padding: 0px 15px !important;
  text-align: right !important;
}

.v-btn:hover > .v-btn__content .v-badge .v-badge__badge .badge-number {
  color: white !important;
}

.v-btn:focus > .v-btn__content .v-badge .v-badge__badge .badge-number {
  color: white !important;
}
.v-list__tile__sub-title {
  overflow: visible;
  white-space: normal;
  line-height: 1.2rem;
  height: auto;
}

.v-toolbar__items {
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0px
}

</style>
