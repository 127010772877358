<template>
  <PContainer
    fluid
    class="px-0"
  >
    <PRow class="mx-3 mx-md-5 my-3">
      <h2>FATCA Renewals</h2>
    </PRow>

    <PRow class="flex-nowrap overflow-auto mx-0">
      <PColumn class="mb-2 pl-3 pl-md-5" sm="9" md="5" lg="4">
        <FilterCard
          :status="'overdue'"
          :clientCount="statusCount.overdue"
          :mainText="'renewals overdue'"
          :detailsText="'View your client list'"
          :statusLabelMap="statusLabelMap"
          :statusVariantMap="statusVariantMap"
          class="h-100"
          @filter="updateStatus(statusItems[1])"
        />
      </PColumn>
      <PColumn class="mb-2" sm="9" md="5" lg="4">
        <FilterCard
          :status="'at_risk'"
          :clientCount="statusCount.at_risk"
          :mainText="'a renewal at risk'"
          :detailsText="'They\'ll be overdue in 14 days or less.'"
          :statusLabelMap="statusLabelMap"
          :statusVariantMap="statusVariantMap"
          class="h-100"
          @filter="updateStatus(statusItems[2])"
        />
      </PColumn>
      <PColumn class="mb-2 pr-3 pr-md-5" sm="9" md="5" lg="4">
        <FilterCard
          :status="'upcoming'"
          :clientCount="statusCount.upcoming"
          :mainText="'upcoming renewals'"
          :detailsText="'They\'ll be overdue in 60 days or less.'"
          :statusLabelMap="statusLabelMap"
          :statusVariantMap="statusVariantMap"
          class="h-100"
          @filter="updateStatus(statusItems[3])"
        />
      </PColumn>
    </PRow>
    <PLineBreak />
    <PRow class="mx-3 mx-md-5 mb-4 mb-md-2 mb-lg-1 mt-4">
      <PColumn
        md="6"
        lg="5"
        class="mb-2 pl-lg-0"
      >
        <SearchBar
          v-model="selectedClient"
          :list-api="clientsApi"
          :placeholder-text="'Search Clients'"
          :label-key="'name'"
        />
      </PColumn>
      <PColumn md="6" class="mt-lg-1">
        <PDropdown
          class="mr-1"
          :text="statusText"
          size="sm"
          variant="light"
          toggle-class="rounded-pill text-primary bg-white"
        >
          <PDropdownItem
            v-for="item in statusItems"
            :key="item.label"
            @click="updateStatus(item)"
          >
            {{ item.label }}
          </PDropdownItem>
        </PDropdown>
        <PDropdown
          class="mr-1"
          :text="teamText"
          size="sm"
          variant="light"
          toggle-class="rounded-pill text-primary bg-white"
        >
          <PDropdownItem @click="updateTeam()">All</PDropdownItem>
          <PDropdownItem
            v-for="team in advisorTeamsSorted"
            :key="team.value"
            @click="updateTeam(team)"
          >
            {{ team.text }}
          </PDropdownItem>
        </PDropdown>
      </PColumn>
    </PRow>
    <PRow class="mx-0">
      <ClientsTable
        @reset-filters="resetFilter"
        @show-toast="showToast"
        :clientId="selectedClient ? Number(selectedClient.id) : null"
        :status="selectedStatus"
        :team="selectedTeam"
      />
    </PRow>
    <PToast
      key="fatcarenewals_regulation_toast"
      :visible="notification.show"
      auto-hide-delay="9000"
      toaster="b-toaster-bottom-left"
      @hidden="notification.show = false"
    >
      {{notification.message}}
    </PToast>
    <ModalWrapper
      v-on:submitQuestionnaire="submitQuestionnaire"
    />
  </PContainer>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { fetchFATCARenewaltatusCount } from 'Services/api/FATCARenewalsList';
import { fetchFATCARenewalsList } from 'Services/api/FATCARenewalsList';
import FilterCard from 'Components/Shared/FilterCard';
import ClientsTable from './ClientsTable';
import ModalWrapper from '../../ModalWrapper';
import { FATCA_RENEWAL_MAP_STATUS_LABEL, FATCA_RENEWAL_MAP_STATUS_VARIANT, STATUS_ITEMS } from './constants';

export const defaultFilterLabels = {
  status: 'Status',
  team: 'Team',
};

export default {
  name: 'FATCARenewals',
  components: {
    FilterCard,
    ClientsTable,
    ModalWrapper,
  },
  data() {
    return {
      clientsApi: {
        api: fetchFATCARenewalsList,
        options: {
          limit: 15,
          offset: 0,
          sortBy: 'next_fatca_renewal_due',
          sortDirection: 'desc',
          sortDescending: false,
        },
        searchParam: 'searchVal',
      },
      selectedStatus: null,
      statusCount: {},
      statusText: defaultFilterLabels.status,
      statusItems: STATUS_ITEMS,
      selectedTeam: null,
      teamText: defaultFilterLabels.team,
      selectedClient: null,
      notification: {
        show: false,
        message: '',
      },
      statusLabelMap: FATCA_RENEWAL_MAP_STATUS_LABEL,
      statusVariantMap: FATCA_RENEWAL_MAP_STATUS_VARIANT,
    };
  },
  computed: {
    ...mapGetters([
      'advisorTeams',
    ]),
    advisorTeamsSorted() {
      let advisorTeamsCopy = _.cloneDeep(this.advisorTeams);
      advisorTeamsCopy = _.orderBy(advisorTeamsCopy, ['text']);
      return advisorTeamsCopy;
    },
  },
  methods: {
    resetFilter() {
      this.updateTeam();
      this.updateStatus();
    },
    async getStatusCounts() {
      this.statusCount = await fetchFATCARenewaltatusCount();
    },
    updateStatus(item) {
      if (item && item.label !== 'All') {
        this.selectedStatus = item.key;
        this.statusText = item.label;
        this.selectedClient = null;
      } else {
        this.selectedStatus = null;
        this.statusText = defaultFilterLabels.status;
      }
    },
    updateTeam(team) {
      if (team) {
        this.selectedTeam = team.value;
        this.teamText = team.text;
        this.selectedClient = null;
      } else {
        this.selectedTeam = null;
        this.teamText = defaultFilterLabels.team;
      }
    },
    showToast(message) {
      this.notification = {
        message,
        show: true,
      };
    },
    async submitQuestionnaire() {
      try {
        const inProgressWorkflowsForClient = await inProgressWorkflowsForClient(
          1,
          [client.id],
          'fatca_repapering',
        );
        if (inProgressWorkflowsForClient.count === 0) {
          await createAndStartFATCARenewalWorkflow(client.guid);
        }
      } catch (error) {
        this.$emit('show-toast', 'Something went wrong. Please try again, or contact us for more details.')
        this.loadClients(this.query.offset)
      }
    },
  },
  async mounted() {
    await this.getStatusCounts()
  },
};
</script>
